<template>
    <v-menu :ref="refElement" v-model="activeInput" :close-on-content-click="false" :nudge-right="40"
        transition="scale-transition" max-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="inputDateFormated" :label="title" class="white--text mt-5 mr-2 ml-2" persistent-hint
                prepend-icon="fas fa-calendar-alt" v-bind="attrs" v-on="on"
                @blur="date = parseDate(inputDateFormated)" :clearable="clearable"
                :required="requiredInput">
            </v-text-field>
        </template>
        <v-date-picker v-model="date" @input="activeInput = false" locale="es" :min="minDate"
        color="primary" :disabled="disabled">
        </v-date-picker>
    </v-menu>
</template>
<script>
import { parseDate, parseDateNatural } from '@/utils/DateUtil';

export default {
    name: 'SingleCalendar',
    props: {
        refElement: { type: String, required: true },
        title: { type: String, required: true },
        inputDate: { type: String, required: false },
        disabled: { type: Boolean, default: false },
        minDate: { type: String, default: null },
        requiredInput: { type: Boolean, default: false },
        clearable: { type: Boolean, default: true }
    },
    data() {
        return {
            date: this.inputDate,
            inputDateFormated: parseDateNatural(this.inputDate),
            activeInput: false
        }
    },
    methods: {
        parseDate(date) {
            return parseDate(date);
        }
    },
    watch: {
        inputDate(newvalue) {
            if(newvalue !== null && newvalue !== '') {
                this.date = newvalue;
                this.inputDateFormated = parseDateNatural(newvalue);
            }
        },
        date(newvalue) {
            this.$emit('update:input-date', newvalue);
            this.inputDateFormated = parseDateNatural(newvalue);
        },
        minDate(newvalue) {
            if(newvalue !== null && this.date < newvalue) {
                this.date = newvalue
            }
        }
    }
}
</script>